/* Used in PlanningFeaturesComponent.js */

.plannig-features-section {
    position: relative;
    background-color: #fff;
    height: 100vh;
    overflow: hidden;
}

.planning-features-container {
    height: 90vh;

}

.planning-item {
    align-items: center;
    justify-content: center;
    background-color: rgba(3, 83, 151, 0.2);
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(55, 55, 55, 0.2);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    padding: 0.8rem;
    gap: 0;
    transition: ease-in 0.3s;
}

.planning-item:hover {
    transform: scale(1.15);
    background-color: #FCD900;
}

.planning-item:hover .mobile-feature-icon {
    opacity: 0.8;
}

.planning-item-title {
    font-size: 1.2rem;
}

.mobile-feature-icon {
    opacity: 0.3;
    filter: invert(22%) sepia(48%) saturate(1854%) hue-rotate(197deg) brightness(98%) contrast(95%);
    transition: all 0.5s;
}

.mobile-feature-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

hr {
    border: 1px solid rgba(55, 55, 55, 0.1);
    width: 100%;
    margin: 0px 0 8px 0;
}

.planning-feature-desc {
    font-size: 1rem;
}

@media screen and (max-width: 490px) {
    .planning-item {
        padding: 2px;
        height: 100%;
    }

    .plannig-features-section {
        height: auto;
    }

    .planning-features-container {
        height: auto;
    }

}