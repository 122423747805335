/* Used in SidebarComponent.js */
.btn-sidebar-toggler
{
    border: none;
    cursor: pointer;
    z-index: 2000;
    padding-top: 15px;
    padding-bottom: 15px;
    position: fixed;
    outline-style: none;
    left: 0;
    background-color: #035397;
}

.btn-sidebar-toggler:active
{
    border: none;
}

aside
{
    height: 100vh;
    width: 100%;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.slidebar-item
{
    list-style-type: none;
}

.slidebar-link
{
    color: #000000;
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    background-size: 200% auto;
}

.slidebar-link:hover
{
    background-image: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);
    background-position: right center;
    color: #ffffff;
    transition: all 0.5s;
}