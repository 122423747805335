/* Used in MandeKarfarmaComponnet.js */

.mandekarfarma-image {
    position: absolute;
    left: 5%;
    transition: ease-out 0.3s;
}

.mandekarfarma-image:hover {
    transform: scale(1.1);
}

.mandekarfarma-features {
    width: 80%;
    border-radius: 10px;
    background-color: rgba(3, 83, 151, 0.2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 0.8rem;
    transition: all 0.5s;
}

.mandekarfarma-features:hover {
    transform: scale(1.2);
    background-color: #FCD900;
}
.mandekarfarma-features-container {
    height: 600px;
}

.mandekarfarma-features img {
    width: 50px;
    height: 50px;
    filter: invert(31%) sepia(5%) saturate(4%) hue-rotate(358deg) brightness(101%) contrast(88%);
    opacity: 60%;
}

.vl {
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    height: 60px;
}

@media screen and (max-width: 500px) {

    .mandekarfarma-image {
        display: none;
    }
    
    .mandekarfarma-features-container {
        text-align: center;
    }

    .mandekarfarma-features {
        width: auto;
        margin: 0 10px;
    }
}