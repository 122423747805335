/* Used in DashboardIntroComponent.js */
.planning-intro
{
    /* height: 80vh; */
    /* background-image: url('../resources/images/bg2.webp'); */
    position: relative;
    height: 40vh;
    width: 100%;
    background-color: #555555;
    box-shadow: 0 0 15px rgba(22,22,22,0.4);
    overflow: hidden;
}

.planning-intro::before {
    content: "";
    background-image: url('../resources/images/sb-planning.webp');
    background-size: cover;
    position: absolute;
    background-attachment: fixed;
    background-repeat: no-repeat;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
}

.section-title {
    color: #fff;
    font-size: 3rem;
    text-align: center;
}


@media screen and (max-width: 490px) {
    .planning-intro::before {
        background-image: url('../resources/images/sb-planning-mobile.webp');
    }
    .section-title {
        font-size: 2rem;
    }
        
}