
.component-item-title
{
    color: #3A5BA0;
    cursor: pointer;
    font-size: 1.1rem;
    opacity: 0.9;
    transition: all 0.5s;
}

.component-item
{
    align-items: center;
    background-color: rgba(3, 83, 151, 0.2);
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(55, 55, 55, 0.2);
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: all 0.3s;
    width: 85%;
}

@media screen and (max-width: 490px) {
    .component-item {
        width: 95%;
        height: 100%;
    }

    .component-container {
        height: 100%;
    }

    .component-item-title {
        font-size: 0.9rem;
        opacity: 1;
    }
}

.component-item:hover 
{
    transform: scale(1.15);
    background-color: #FCD900;
    box-shadow: 0 0 20px rgba(55, 55, 55, 0.1);
}

.component-item:hover .feature-icon
{
    opacity: 1 ;
    transform: scale(1.08);
}


.component-item:hover .component-item-title
{
    opacity: 1 ;
    transform: scale(1.08);
}

.feature-icon 
{
    transition: all 0.7s;
}
.component-item > svg
{
    margin-bottom: 15px;
}

.component-item > p
{
    padding-top: 20px;
}

.feature-icon 
{
    opacity: 0.4;
    filter: invert(22%) sepia(48%) saturate(1854%) hue-rotate(197deg) brightness(98%) contrast(95%);
}