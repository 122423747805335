/* Used in ScrollToTopComponent.js */
#btn-scroll {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 15px;
  cursor: pointer;
  background-color: #035397;
  color: #ffffff;
  border: none;
  z-index: 1000;
}
