/* Navbar used in NavbarComponent.js */
.navbar {
  width: inherit;
  display: flex;
  justify-content: space-between;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: larger;
}

.nav-item {
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.nav-arrow {
  filter: invert(100%) sepia(93%) saturate(1%) hue-rotate(246deg)
    brightness(105%) contrast(100%);
}
.nav-item:hover .nav-link {
  color: #000000;
  transition: all 0.3s;
}

.nav-link {
  font-size: 1.2rem;
  color: #ffffff;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 5px;
}

.navbar-brand {
  color: #ffffff;
  padding-left: 15px;
  text-align: center;
  transition: all 0.5s;
}

.navbar-brand:hover {
  transition: all 0.5s;
}
