/* Used in FooterComponent.css */
footer {
  background-image: url("../resources/images/sb-footer.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  padding-right: 60px;
}

.footer-ul > li {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: start;
}

.footer-ul > li > a {
  color: #fafafa;
  transition: all 0.5s;
  font-family: IRANSansNumbs;
}

.footer-ul > li > a:hover {
  color: #fcd900;
  transition: all 0.5s;
}

.description-paragraph-footer {
  color: #fafafa;
}

.social-networks {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-networks > li {
  margin-left: 30px;
}

.border-top-enable {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.border-top-enable p {
  color: #fafafa;
}

.footer h3 {
  color: #fafafa;
}

.footer-title {
  color: #fafafa;
  text-align: start;
  font-size: x-large;
}

.footer-footer {
  font-size: 1rem;
  font-family: IRANSansNumbs;
  opacity: 0.8;
}

.footer-number {
  font-family: IRANSansNumbs;
}

.demo-request {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  justify-self: center;
}

.demo-request h2 {
  color: #fafafa;
}

.behinegar-logo {
  margin-top: 30px;
  width: 180px;
  height: 70px;
  opacity: 0.3;
}

p > img,
.footer-img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(267deg)
    brightness(106%) contrast(96%);
  opacity: 0.7;
}

@media screen and (max-width: 500px) {
  footer {
    background-image: url("../resources/images/sb-footer-mobile.webp");
    height: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .footer-feature-container {
    display: none;
  }

  .footer-contact-us {
    width: 400px;
  }

  .demo-request {
    justify-content: center;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .demo-request h2 {
    font-size: 1.2rem;
  }

  .description-paragraph-footer {
    margin-right: 10px;
  }

  .footer-ul {
    margin-right: 15px;
  }

  .footer-title {
    margin-right: 20px;
  }

  .description-paragraph-footer {
    margin-right: 0;
  }
}
