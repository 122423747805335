/* Header Infographic */
/* ----------------------------------------------
/* ----------------------------------------------
 * Generated by Animista on 2022-7-16 12:24:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.introduce-col > * {
  margin-bottom: 10px;
}

.header-title {
  color: #fff;
  font-size: 7rem;
  display: inline-block;
  margin-top: 9rem;
}

.header-info {
  font-size: 4rem;
  color: #fff;
}
.header-text {
  font-size: 2rem;
  color: #fff;
}

.header-buttons {
  margin-top: 3rem;
}

.introduce-col {
  -webkit-animation: slide-in-blurred-right 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    both;
  animation: slide-in-blurred-right 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

#navbar-phone-onMobile {
  display: none;
}

@media screen and (max-width: 500px) {
  .introduce-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  div > h1 {
    font-size: 1.3rem;
    text-shadow: 0 0 15px rgba(22, 22, 22, 0.6);
  }

  .header-title {
    color: #fff;
    font-size: 5rem;
    display: inline-block;
    margin-top: 1rem;
    text-shadow: 0 0 15px rgba(22, 22, 22, 0.6);
  }

  .header-info {
    font-size: 3rem;
    text-align: center;
  }

  #navbar-phone-onMobile {
    display: block;
  }
}
