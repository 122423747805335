/* Used in MobileFeaturesComponent.js */
.mobile-image {
    transform: scale(1);
}

@media screen and (max-width: 1100px) {
    .mobile-image {
        transform: scale(0.8);
    }

    .mobile-item {
        width: 100%;
    }
}



.mobile-features-section {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    height: 120vh;
}

.mobile-features-section::before {    
    content: "";
    background-image: url('../resources/images/bg-wave.svg');
    background-position: center;
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.7;
    transform: scale(1.3);
}


.mobile-feature-container {
    height: 100%;
}

.mobile-item {
    align-items: center;
    justify-content: center;
    background-color: rgba(3, 83, 151, 0.2);
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(55, 55, 55, 0.2);
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 0.8rem;
    gap: 0;
    transition: all 0.5s;
}

.mobile-item:hover {
    transform: scale(1.2);
    background-color: #FCD900;
}

.mobile-item:hover .mobile-feature-icon {
    opacity: 0.8;
}

.mobile-item-title {
    font-size: 1rem;
}

.mobile-feature-icon {
    opacity: 0.3;
    filter: invert(22%) sepia(48%) saturate(1854%) hue-rotate(197deg) brightness(98%) contrast(95%);
    transition: all 0.5s;
}

.mobile-feature-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

hr {
    border: 1px solid rgba(55, 55, 55, 0.1);
    width: 100%;
    margin: 0 0 8px 0;
}


@media screen and (max-width: 500px) {
    .mobile-image {
        transform: scale(0.8);
    }

    .mobile-item {
        width: 50%;
    }

    .mobile-features-section {
        height: auto;
    }

    .mobile-feature-container {
        height: 50%;
        align-items: center;

    }
}
