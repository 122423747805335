.cost-feature-section {
    height: 110vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.cost-feature-container {
    width: 120%;
}

.cost-column {
    padding: 0;
}

.cost-puzzle-piece {
    transition: all 0.5s;
}

.cost-puzzle-piece:hover {
    transform: scale(1.1);
    z-index: 99;
}

.cost-column img {
    width: 270px;
    height: 270px;
}

.cost-column p {
    width: 150px;
    text-align: center;
    font-size: 1.2rem;
}

.cost-column-01 {
    position: relative;
    left: 8em;
}

.cost-column-02,
.cost-column-04,
.cost-column-06,
.cost-column-08{
    position: relative;
    top: 8em;
}

.cost-column-03,
.cost-column-05,
.cost-column-07,
.cost-column-09 {
    bottom: 5.5em;
    right: 2em;

}

.cost-column-10 {
    top: 7.5em;
    right: 9em;
}

.cost-column-02 {
    left: 6em;
}

.cost-column-03 {
    right: -4em;
}

.cost-column-04 {
    left: 2em;
}

.cost-column-05 {
    right: -0.01em;
}

.cost-column-06 {
    right: 2em;
}

.cost-column-07 {
    right: 3.6em;
}

.cost-column-08 {
    right: 5.5em;
}

.cost-column-09 {
    right: 7.5em;
}

.cost-column-03 p,
.cost-column-05 p,
.cost-column-07 p,
.cost-column-09 p {
    position: relative;
    right: 60px;
    bottom: 10px;
}

.cost-column-02 p,
.cost-column-04 p,
.cost-column-06 p,
.cost-column-08 p {
    position: relative;
    right: 90px;
    bottom: 10px;
}

@media screen and (min-width:1440px) and (max-width : 1500px) {
    .cost-column img {
            width: 315px;
            height: 315px;
        }
    
        .cost-column-02,
        .cost-column-04,
        .cost-column-06,
        .cost-column-08 {
            top: 9em;
        }

        .cost-column-03,
        .cost-column-05,
        .cost-column-07,
        .cost-column-09 {
            bottom: 85px;
        }
        .cost-column-02 {
            left: 7em;
        }

        .cost-column-03 {
            right: -5.5em;
        }

        .cost-column-04 {
            right: -55px;
            top: 150px;
        }

        .cost-column-05 {
            right: -20px;
        }

        .cost-column-06 {
            right: 15px;
            top: 150px ;
        }

        .cost-column-07 {
            right: 45px;
        }

        .cost-column-08 {
            right: 80px;
            top: 160px;
        }

        .cost-column-09 {
            right: 115px;
        }

        .cost-column-10 {
            top: 150px;
        }

        .cost-column-03 p,
        .cost-column-05 p,
        .cost-column-07 p,
        .cost-column-09 p {
            right: 90px;
            bottom: 10px;
        }
}

@media screen and (min-width: 1550px) {
    .cost-column img {
        width: 310px;
        height: 310px;
    }
    
    .cost-column-03,
    .cost-column-05,
    .cost-column-07,
    .cost-column-09 {
        bottom: 110px;
    }

    .cost-column-03 p,
    .cost-column-05 p,
    .cost-column-07 p,
    .cost-column-09 p {
        right: 90px;
        bottom: 20px;
    }
}

@media screen and (max-width: 500px) {
    .cost-feature-section {
        display: none;
    }
}