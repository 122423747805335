.contract-feature-section {
    margin: 0;
    height: 120vh;
    overflow: hidden;
}

.contract-feature-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110vw;
    position: relative;
    left: 4%;
}

.dark-blue-poly {
    transition: ease-out 0.3s;
}

.dark-blue-poly:hover {
    transform: scale(1.1);
    opacity: 1;
    cursor: pointer;
}

.contract-columns {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}


.contract-col-3 {
    margin-left: -1.1em;
    margin-right: -1.1em;
}

.contract-col-2,
.contract-col-4,
.contract-col-1,
.contract-col-5 {
    position: relative;
}


.contract-col-1 {
    right: 10em;
}

.contract-col-2 {
    right: 4.5em;
}

.contract-col-4 {
    left: 4.5rem;
}

.contract-col-5 {
    left: 10em;
}

.dark-blue-poly {
    width: 14rem;
    height: 90%;
    opacity: 0.8;
}

@media screen and (min-width:1440px) and (max-width : 1500px) {
    .contract-feature-container {
        width: 100vw;
        display: flex;
        justify-content: center;
        right: 4%;
    }

    .contract-col-3 {
        margin-left: -4em;
        margin-right: -4em;
    }

    .contract-col-1 {
        right: 13em;
    }

    .contract-col-5 {
        left: 13em;
    }
}

@media screen and (min-width: 1550px) {
    .contract-feature-container {
        width: 100vw;
        display: flex;
        justify-content: center;
        right: 5rem;
    }

    .dark-blue-poly {
    width: 15rem;
    }

    .contract-col-3 {
        margin-left: -5em;
        margin-right: -5em;
    }

    .contract-col-1 {
        right: 13em;
    }

    .contract-col-5 {
        left: 13em;
    }

    

}

@media screen and (max-width: 500px) {
    .contract-feature-section {
        display: none;
    }
}



















/* .contract-feature-section {
    height: 110vh;
    text-align: center;
    background-color: #deefff;
}

.contract-feature-container {
    height: 90vh;
    display: flex;
}

.contract-columns {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.contract-col-1 {
    top: 15%;
    right: 11%;
}

.contract-col-2, .contract-col-4 {
    top: 19%;
}

.contract-col-2 {
    right: 5.5%;
}

.contract-col-4 {
    left: 5.5%;
}

.contract-col-5 {
    top: 15%;
    left: 11%;
}

.shape-left-medium {
    left: 12%;
    top: 1%;
}

.shape-left-small {
    position: relative;
    left: 18%;
    bottom: 1%;
}

.shape-right-small {
    position: absolute;
    top: -80px;
}

@media screen and (max-width : 1440px) {
    .contract-col-1 {
        top: -52px;
        right: 130px;
    }

    .contract-col-3 {
        top: 25px;
    }

    .contract-col-5 {
        top: 50px;
        left: 130px;
    }

    .shape-rightbottom-medium {
        position: absolute;
        top: -10px;
        margin-left: 20px;
    }

    .shape-righttop-medium {
        right: 10px;
    }

    .shape-right-small {
        top: 105px;
    }
}

@media screen and (min-width:1440px) and (max-width : 1500px) {
    .contract-col-1 {
        top: 80px;
        right: 160px;
    }

    .contract-col-5 {
        top: 50px;
        left: 130px;
    }

    .shape-rightbottom-medium {
        position: absolute;
        top: -5px;
    }

    .shape-righttop-medium {
        right: -10px;
    }

    .contract-col-5 {
        left: 160px;
        top: 80px;

    }
}
.contract-poly-dark,
.contract-poly-light {
    transition: all 0.4s;
}

.contract-poly-dark:hover,
.contract-poly-light:hover {
    opacity: 1;
    transform: scale(1.2);
}

.shape-left-small,
 .shape-left-medium,
 .shape-rightbottom-medium,
 .shape-righttop-medium,
 .shape-right-small {
    position: relative;
}

.shape-rightbottom-medium {
    right: 13%;
    bottom: 1%;
}

.shape-righttop-medium {
    top: 1%;
    left: 1%;
}

.shape-right-small {
    bottom: 53%;
    right: 8%;
}








 */




/* .contract-feature-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    background-color: rgb(230,230,230);
}

.contract-columns {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
}

.contract-poly-dark {
    background-image: url(../resources/images/blue-dark.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 90%;
    height: 30%;
    opacity: 60%;
    transition: all 0.3s;
}

.contract-poly-dark:hover {
    transform: scale(1.1);
    opacity: 90%;
}

.contract-poly-light {
    background-image: url(../resources/images/blue-light.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 90%;
    height: 30%;
    opacity: 60%;
}

.contract-col-3 {
    margin-top: -120px;
    margin-right: -130px;
}

.contract-col-4, .contract-col-2 {
    margin-bottom: -110px;
}

.contract-col-4 {
    margin-right: -130px;
}

.contract-col-1 {
    margin-left: -130px;
    margin-bottom: -100px;
}

.contract-col-5 {
    margin-right: -130px;
    margin-bottom: -40px;
}

.shape-left-medium {
    position: relative;
    left: 15px;
    top: 20px;
    opacity: 60%;
}

.shape-left-small {
    position: relative;
    left: 30px;
    bottom: 10px;
    opacity: 60%;
}

.shape-righttop-medium {
    position: relative;
    top: 20px;
    opacity: 60%;
}

.shape-right-small {
    position: relative;
    bottom: 80px;
    right: 25px;
    opacity: 60%;
}

.shape-rightbottom-medium {
    position: relative;
    right: 35px;
    bottom: 15px;
    opacity: 60%;
}

.contract-poly-dark img,
 .contract-poly-light img {
    position: relative;
    right: 110px;
    top: 50px;
}
@media screen and (min-width : 1400px) {
    .contract-feature-container {
        margin-left: 160px;
    }
    .contract-col-1 {
        margin-left: -300px;
        margin-bottom: -110px;
    }
    .contract-col-4,
    .contract-col-2 {
        margin-bottom: -110px;
    }

    .contract-col-3 {
        margin-top: -120px;
        margin-right: -300px;
    }
    
    .contract-col-4 {
        margin-right: -300px;
    }

    
    .contract-col-5 {
        margin-right: -300px;
        margin-bottom: -40px;
    }

    .shape-left-medium {
        right: 65px;
        top: 20px;
    }

    .shape-left-small {
        right: 50px;
        bottom: 10px;
    }

    .shape-righttop-medium {
        position: relative;
        top: 20px;
        right: 70px;
        opacity: 60%;
    }

    .shape-rightbottom-medium {
        position: relative;
        right: 110px;
        bottom: 15px;
        opacity: 60%;
    }
} */