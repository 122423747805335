.procurement-middle-container {
    position: absolute;
    z-index: 9;
    overflow: hidden;
}

.procurement-middle {
    width: 300px;
    height: 300px;
    border-radius: 600px;
    background-color: rgba(0, 0, 0, 0.6);
    border: 15px solid rgba(3, 83, 151,0.6);
    display: flex;
    color: #fafafa;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    text-align: center;
}

@media screen and (max-width:1280px) {
    .procurement-middle-container {
        left: 400px;
    }
    
}

.procurement-right {
    margin-left: -50px;
    margin-right: 50px;
    z-index: 8;
    opacity: 0.8;
}

.procurement-left {
        height: 650px;
        width: 1000px;
        margin-right: -20px;
        z-index: -1;
}

@media screen and (max-width: 500px) {
    .procurement-left {
        display: none;
    }

    .procurement-right {
        opacity: 0.8;
        height: 50vh;
        margin-bottom: 50px;
        margin-right: 10px;
    }

    .procurement-middle-container {
        display: none;
    }
}