/* Used in WorkflowMaterialRequestComponent.js */
hr {
    border: 1px solid rgba(55, 55, 55, 0.1);
    width: 100%;
    margin: 0 0 8px 0;
}

.workflow-example-section {
    height: 100vh;
}

.planning-section-title {
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.workflow-column {
    transition: ease-in 0.3s;
    opacity: 0.7;
}

.workflow-column:hover {
    transform: scale(1.1);
    opacity: 1;
}

@media screen and (max-width : 500px) {
    .workflow-example-section {
        display: none;
    }
    
}