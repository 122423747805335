/* Used in DashboardIntroComponent.js */

.formsaz-component {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    transition: ease-in-out 0.3s;
}

.formsaz-component:hover {
    transform: scale(1.1);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}

.formsaz-component p{
    letter-spacing: 0px;
}

.formsaz-component hr {
    margin-top: 8px;
}

.formsaz-container {
    width: 100%;
}


@media screen and (max-width: 500px) {
    
    .formsaz-component {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }
}
