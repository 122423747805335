/* Used in DropDownNavbarComponent.js */
.dropdown-item-0:hover
{
    background-color: #fafafa;
    border: none;
}

.dropdown-item-0-child {
    display: none;
    position: absolute;
    min-width: 160px;
    z-index: 1;
}

.dropdown-item-0-child a
{
    color: black;
    padding: 6px 8px 6px 0px;
    text-decoration: none;
    font-size: 0.75rem;
    border-radius: 5px;
}

div.dropdown-item-highlight:hover 
{
    background-color: #f0f0f0;
}

.dropdown-item-0-child-box {
    background-color: #fafafa;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
    border-top-right-radius: 0;
    margin-top: 10px;
    margin-right: -15px;
}




/*********************************************************************/
/***                       منوی سلسله مراتبی                      ***/
/*********************************************************************/
.dropdown-item-arrow {
    min-width: 10%;
    display: flex;
    align-items: center;
    padding: 4px;
}

.dropdown-item-arrow-icon {
    text-decoration: none;
    font-size: 16px;
}

.dropdown-item-link {
    min-width: 90%;
}

.dropdown-item-1, .dropdown-item-2, .dropdown-item-3 {
    display: flex; 
    flex-direction: row;
}


.dropdown-item-1-child, .dropdown-item-2-child, .dropdown-item-3-child {
    position: absolute;
    max-width: 0px;
    display:none;
    flex-direction: row-reverse;
}

.dropdown-item-1-child-box, .dropdown-item-2-child-box, .dropdown-item-3-child-box {
    background-color: #eeeeee;
    min-width: 200px;
}

.dropdown-item-0:hover .dropdown-item-0-child
{
    display: flex;
}

.dropdown-item-1:hover .dropdown-item-1-child
{
    display: flex;
}

.dropdown-item-2:hover .dropdown-item-2-child
{
    display: flex;
}

.dropdown-item-3:hover .dropdown-item-3-child
{
    display: flex;
}
