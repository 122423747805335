/* Used in HeaderComponent.js */
/* ----------------------------------------------
 * Generated by Animista on 2022-7-13 17:22:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.02) translateY(-15px);
    transform: scale(1.02) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.05) translateY(-15px);
    transform: scale(1.05) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

#home-header {
  /* background-image: url('../resources/images/sb-header.webp'); */
  /* background-image: url('https://mapsunhomefiles.s3.ir-thr-at1.arvanstorage.com/headerbg.webp'); */
  /* background-repeat: no-repeat;
    background-size: cover; */
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: #272727;
  box-shadow: 0 0 15px rgba(22, 22, 22, 0.6);
  overflow: hidden;
}

#home-header::before {
  content: "";
  background-image: url("../resources/images/sb-header.webp");
  background-size: cover;
  position: absolute;
  background-repeat: no-repeat;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
  -webkit-animation: kenburns-top 5s ease-out both;
  animation: kenburns-top 5s ease-out both;
}

.custom-container {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 490px) {
  #home-header::before {
    background-image: url("../resources/images/sb-header-mobile.webp");
  }
}
