/* Used in PlanningProcessComponent.js */
hr {
    border: 1px solid rgba(55, 55, 55, 0.1);
    width: 100%;
    margin: 0 0 8px 0;
}

.plannig-process-section {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    height: 100vh;
}

.plannig-process-section::before {
    content: "";
    background-image: url('../resources/images/bg-wave2.svg');
    background-position: center;
    background-size: contain;
    position: absolute;
    background-repeat: no-repeat;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.6;
    transform: scale(1.3);
}

.planning-section-title {
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}


@media screen and (max-width: 500px) {
    .planning-process-image {
        display : none ;
    }

    .plannig-process-section {
        height: auto;
        margin-bottom: 2rem;
    }
}