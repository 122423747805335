.estimate-features-section {
    position: relative;
    height: 220vh;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
}

.estimate-features-section::before {
    content: "";
    background-image: url('../resources/images/estimate-background.svg');
    background-size: cover;
    position: absolute;
    background-repeat: no-repeat;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
}

.estimate-images-container {
    height: 100vh;
}

.estimate-man {
    width: 400px;
    height: 500px;
    position: absolute;
    left: 50px;
    top: 200px;
    opacity: 0.9;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.2);

}

.estimate-men {
    width: 500px;
    height: 600px;
    position: absolute;
    left: 350px;
    opacity: 0.8;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.2);
}

.estimate-circle {
    position: absolute;
    z-index: 99;
    left: 370px;
    top: 130px;
}

.estimate-text-container {
    height: 100%;
    padding-left: 100px;
    margin-top: 40px;
    font-size: 1.3rem;
}

@media screen and (max-width: 500px) {
    .estimate-features-section {
        position: relative;
        height: auto;
        width: 100%;
        background-color: #fff;
        overflow: hidden;
    }

    .estimate-images-container,
    .estimate-text-container {
        display: none;
    }
}