/* This file will be effective anywhere! */

@font-face {
  src: url("../resources/fonts/IRANSans/WebFonts/fonts/woff/IRANSansWeb.woff");
  font-family: "IRANSansWeb";
}

@font-face {
  font-family: "IRANSansNumbs";
  src: url("../resources/fonts/IRANSans/Farsi_numerals/webFonts/fonts/woff/IRANSansWeb\(FaNum\)_UltraLight.woff");
}

@font-face {
  font-family: 'IRANSansMobile';
  src: url("../resources/fonts/IRANSans/MobileFonts/IRANSansMobile_UltraLight.ttf");
}

@font-face {
  src: url("../resources/fonts/webfonts/fa-duotone-900.woff2");
  font-family: "FontAwesome";
}

body::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: rgb(210, 210, 210);
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(180,180,180);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

html,
body,
header,
footer,
main,
div,
table,
section,
nav,
ul,
ol,
thead,
tbody,
tfoot,
th,
tr,
td,
form,
aside,
article,
menu,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span,
li,
label,
input,
button,
textarea {
  direction: rtl;
  font-family: "IRANSansMobile";
  padding: 0;
  margin: 0;
  user-select: none;
}

@media screen and (max-width : 500px) {
html,
body,
header,
footer,
main,
div,
table,
section,
nav,
ul,
ol,
thead,
tbody,
tfoot,
th,
tr,
td,
form,
aside,
article,
menu,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span,
li,
label,
input,
button,
textarea {
  direction: rtl;
  font-family: "IRANSansMobile";
  padding: 0;
  margin: 0;
  user-select: none;
}

.custom-container {
  width: 100%;
  height: 100%;
  margin: 0;
}

}

html {
  scroll-behavior: smooth;
}

h1 {
  color: #fafafa;
}

h2,
h3,
h4,
h5,
h6 {
  color: #001447;
  text-shadow: 0 0 5px rgba(22,22,22,0.3);
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.custom-container {
  width: 90%;
  height: 100%;
  margin: auto;
}

.description-paragraph {
  color: #a9adba;
  font-size: 1rem;
}

.text-justify {
  text-indent: 1cm;
  text-align: justify;
}

.indent-needed {
  text-indent: 1cm;
}

.btn {
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 10px 40px;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 1.2rem;
  color: #ffffff;
}

.btn-blue {
  background-color: #035397;
  transition: all 0.7s;
}

.btn-blue:hover
{
  background-color: #FCD900;
  color: #001E6C;
}

.btn-outlined
{
  border: 2px solid rgba(255, 255, 255, 0.7);
  transition: border 0.5s;
}

.btn-outlined:hover
{
  border: 2px solid #ffffff;
}

.text-center {
  text-align: center;
}

.custom-mt-100px {
  margin-top: 100px;
}

.make-descriptions-smaller {
  font-size: 1rem;
}

.make-100percent {
  width: 100%;
  height: 100%;
}

.no-letter-spacing {
  letter-spacing: 0;
}

.baseIcon {
  color: #7e7b8a;
  transition: all 0.5s;
}

#twitterIcon:hover {
  color: #00acee;
  transition: all 0.5s;
}

#instagramIcon:hover {
  color: #e1306c;
  transition: all 0.5s;
}

#linkedinIcon:hover {
  color: #0e76a8;
  transition: all 0.5s;
}

.text-white {
  color: #ffffff;
}

.letter-spacing-0 {
  letter-spacing: 0;
}

.text-danger {
  color: #ff0000;
}

.small {
  font-size: 0.8rem;
}

@media screen and (max-width: 500px) {
  .btn-large {
    padding: 15px 15px;
    font-size: 0.9rem !important;
  }
}
